import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "2020 年、プロダクト開発合宿に行ってきました！＠伊東",
  "date": "2020-03-27T09:46:23.000Z",
  "slug": "entry/2020/03/27/184623",
  "tags": ["medley"],
  "hero": "./2020_03_27.png",
  "heroAlt": "2020 開発合宿"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、インキュベーション本部の加藤です。
2019 年に新卒でメドレーに入社し、間もなく 1 年が経とうとしています。`}</p>
    <p>{`少し時間が経ってしまったのですが、今回は 1 月に行われたプロダクト開発合宿の様子をお届けします。`}</p>
    <h1>{`プロダクト開発合宿の目的`}</h1>
    <p>{`メドレーでは「1 年間の方針を共有すること」「メンバー間の親睦を深めること」を目的とした開発合宿を、毎年年始に行っています。`}</p>
    <p>{`合宿の恒例行事となっている「1 年間のプロダクト開発の指針」を CTO 平山がプレゼンし、全員で共有する大切な機会です。
また、親睦を深める観点で、集中して一気に何かを開発するような合宿ではなく、普段あまり体験しないであろうアクティビティが中心です。`}</p>
    <h2>{`アクティビティの紹介`}</h2>
    <h3>{`そば打ち体験`}</h3>
    <p>{`今年の合宿のアクティビティは、そば打ち体験でした。
「チームでひとつの作業に臨むことで、普段の業務以外でのメンバー間の絆を深めてもらいたい」という理由から、そば打ちがアクティビティに選ばれました。`}</p>
    <p>{`今回お世話になった`}<a parentName="p" {...{
        "href": "https://taiken-jp.net/kannon/"
      }}>{`観音亭`}</a>{`さんは、他ではなかなか経験のできない「石臼挽き」から体験できるおそば屋さんです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183356.jpg",
      "alt": "20200327183356.jpg"
    }}></img>
    <p>{`石臼挽きを終えたら、挽いたそば粉をこねて伸ばしていきます。
職人さんの説明をよく聞きながら、普段食べている蕎麦の太さになるように薄く生地を伸ばしていきます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183437.jpg",
      "alt": "20200327183437.jpg"
    }}></img>
    <p>{`生地が薄くなりすぎると破れてしまうことも......！`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183459.jpg",
      "alt": "20200327183459.jpg"
    }}></img>
    <p>{`伸ばした生地を大きな包丁で切れば、見慣れた蕎麦の形になっていきます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183538.jpg",
      "alt": "20200327183538.jpg"
    }}></img>
    <p>{`自分たちで打ったそばは、少し太さにばらつきはあるものの、その場で茹でていただきとても美味しくいただくことができました。
他のメンバーもそば打ちを楽しむことができたようで、満足した様子でした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183616.jpg",
      "alt": "20200327183616.jpg"
    }}></img>
    <h2>{`城ヶ崎海岸を散策`}</h2>
    <p>{`そば打ち体験の後は、サスペンスドラマの撮影で使われることでも有名な城ヶ崎海岸に移動し、周辺を散策しました。
門脇吊り橋という大きな吊り橋を渡って少しスリルを味わいつつ、城ヶ崎海岸の絶景を楽しむことができました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183707.jpg",
      "alt": "20200327183707.jpg"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183729.jpg",
      "alt": "20200327183729.jpg"
    }}></img>
    <h2>{`バーベキュー`}</h2>
    <p>{`夕方には宿泊先に到着し、バーベキューを開始！
お酒を飲んだり、雑談したり…時にはプロダクトや組織について熱く語りあったりと楽しい時間を過ごすことができました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183804.jpg",
      "alt": "20200327183804.jpg"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183823.jpg",
      "alt": "20200327183823.jpg"
    }}></img>
    <h2>{`2020 年キックオフ！`}</h2>
    <p>{`バーベキューの後は CTO 平山から 2020 年に向けてのキックオフです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183849.jpg",
      "alt": "20200327183849.jpg"
    }}></img>
    <p>{`まず、2019 年の振り返りから。`}</p>
    <p>{`昨年はメドレーにとってもプロダクトにとっても大きな変化のある 1 年でした。`}</p>
    <p>{`会社としては創業 10 周年を迎え、東証マザーズへ上場を果たしました。
ジョブメドレーの大幅な`}<a parentName="p" {...{
        "href": "https://job-medley.com/release/55/"
      }}>{`デザインリニューアル`}</a>{`や CLINICS の`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/clinics2019100.html"
      }}>{`グッドデザイン・ベスト 100 の受賞`}</a>{`など様々なことがあった 1 年でした。`}</p>
    <p>{`続いて 2020 年について。
詳細は記載できず残念なのですが、なにを目指すのか、それをどう実現していくのか…CTO からのメッセージを全員で確認する良い機会となりました。`}</p>
    <p>{`普段の業務では目の前のタスクばかりに目が向き、つい視野が狭くなってしまうため、日常や業務を離れて少し引いた目でプロダクトや開発組織のこれからについて考える時間も必要なのだと感じることができました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回はメドレーのプロダクト開発合宿の様子をお届けしました。`}</p>
    <p>{`私自身、今回初めての合宿参加でしたが、メンバーと同じ時間を過ごすことで、普段見ることのできない一面を知ることができました。
また、これからの 1 年について考える時間ともなり、2020 年、より良い 1 年にしていきたいです。`}</p>
    <p>{`最後に、今回の合宿の企画運営をしてくださった前田さん・新居さん、本当にありがとうございました！`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200327/20200327183919.jpg",
      "alt": "20200327183919.jpg"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      